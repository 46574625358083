import Types from "./Types";

export const getLiveVideosRequest = (body) => ({
  type: Types.GET_LIVE_VIDEOS_REQUEST,
  payload: body,
});

export const getLiveVideosSuccess = (data) => ({
  type: Types.GET_LIVE_VIDEOS_SUCCESS,
  payload: data,
});

export const getLiveVideosFail = () => ({
  type: Types.GET_LIVE_VIDEOS_FAIL,
});

//LOADING
export const addLiveVideoLoading = (isLoading) => ({
  type: Types.ADD_LIVE_VIDEOS_LOADING,
  payload: isLoading,
});

// Create AdminSection
export const createLiveVideoRequest = (formData, action) => ({
  type: Types.CREATE_LIVE_VIDEO_REQUEST,
  payload: formData,
  action: action,
});

export const createLiveVideoSuccess = ({ data }) => ({
  type: Types.CREATE_LIVE_VIDEO_SUCCESS,
  payload: {
    data,
  },
});

export const createLiveVideoFail = () => ({
  type: Types.CREATE_LIVE_VIDEO_FAIL,
});

export const livevideoReportRequest = (id) => ({
  type: Types.LIVE_VIDEO_REPORT_REQUEST,
  payload: id,
});

export const livevideoReportSuccess = (id) => ({
  type: Types.LIVE_VIDEO_REPORT_SUCCESS,
  payload: id,
});

export const livevideoReportFail = () => ({
  type: Types.LIVE_VIDEO_REPORT_FAIL,
});
