import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import { createLiveVideoRequest } from "../../../../modules/admin-modules/liveVideos/Actions";
import { closeAddLiveVideo } from "../../../../modules/admin-modules/models/Actions";
import { editAdminSectionRequest } from "../../../../modules/admin-modules/sections/Actions";

const AddLiveVideo = ({ isAdd, data, setSelectedData }) => {
  const openChapter = useSelector((state) => state.model.isOpen34);
  const isLoading = useSelector((state) => state.adminLiveVideos.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !isAdd ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة الفيديو" : "تعديل  الفيديو"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    const action = () => {
      dispatch(closeAddLiveVideo());
      setSelectedData({});
      reset();
    };
    let formData = new FormData();
    formData.append("title", values?.title);
    formData.append("zoom_link", values?.zoom_link);
    if (isAdd) {
      dispatch(createLiveVideoRequest(values, action));
    } else {
      dispatch(
        editAdminSectionRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };
  const closeFunc = () => {
    dispatch(closeAddLiveVideo());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openChapter}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="4"
        >
          <Box w="100%">
            <MainInput
              label="اسم الفيديو "
              defaultValues={data?.name}
              error={errors?.name?.message}
              placeholder="ادخل اسم الفيديو "
              register={register}
              name="title"
            />
          </Box>

          <Box w="100%">
            <MainInput
              label="رابط الفيديو "
              defaultValues={data?.name}
              error={errors?.name?.message}
              placeholder="ادخل رابط الفيديو "
              register={register}
              name="zoom_link"
            />
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddLiveVideo;
