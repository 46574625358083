import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddLiveVideo from "../../../containers/admin/liveVideos/models/add-liveVideo";
import SearchBar from "../../../containers/admin/liveVideos/searchbar";
import LiveVideosTable from "../../../containers/admin/liveVideos/table";
import { getLiveVideosRequest } from "../../../modules/admin-modules/liveVideos/Actions";

const LiveVideos = () => {
  const dispatch = useDispatch();
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getLiveVideosRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <LiveVideosTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddLiveVideo
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default LiveVideos;
