import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList, Switch, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { MdWarningAmber } from 'react-icons/md';
import { RiDeleteBinLine, RiFileEditLine, RiCheckFill, RiSwitchLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import axiosInstance from '../../../helpers/api';
import { deleteEventRequest } from '../../../modules/admin-modules/events/Actions';
import { deleteExamRequest, enableExamRequest, publishedExamRequest, enableRedoRequest, publishExamRequest, restrictExamRequest, editExamRequest } from '../../../modules/admin-modules/exams/Actions';
import { openCreateEvent, openCreateExamDataModel } from '../../../modules/admin-modules/models/Actions';

const ExamsTable = ({ setAdd, setSelectedData }) => {
    const exams = useSelector((state) => state.exams.exams)
    const user = useSelector((state) => state.user.userData)

    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.exams.isLoading)
    const publishLoading = useSelector((state) => state.exams.publishLoading)
    const restrictLoading = useSelector((state) => state.exams.restrictLoading)
    const [userRole, setUserRole] = useState('');

    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })

    useEffect(() => {
        if (user?.role?.length > 0) {
            setUserRole(user?.role[0]?.name)
        }
    }, [user?.role])
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        exams?.map((data) => {
            return {
                ...data,
                id: data?.id,
                title: data?.name,
                publish: (<Button
                    onClick={() => dispatch(publishExamRequest(data.id))}
                    isLoading={data?.id == selected?.id ? publishLoading : false}
                    leftIcon={data?.auto_publish_result == 0 ? null : <RiCheckFill />}
                    bg={data?.auto_publish_result == 0 ? 'main_1' : '#ddffc2'}
                    color={data?.auto_publish_result == 0 ? 'white' : 'black'}
                >{data?.auto_publish_result == 0 ? 'نشر التصحيح' : 'تم النشر'}
                </Button>
                ),
                enable: (
                    <Switch size='md'
                        defaultChecked={data?.enable == 0 ? true : false}
                        onChange={() => {
                            dispatch(enableExamRequest(data?.id))
                        }} />
                ),
                redo: (
                    <Switch size='md'
                        defaultChecked={data?.redo_flag == 1 ? true : false}
                        onChange={() => {
                            console.log("Dispatching Redo")
                            dispatch(enableRedoRequest(data?.id))
                        }} />
                ),
                publish_exam: (
                    <Switch size='md'
                        defaultChecked={data?.published == 0 ? true : false}
                        onChange={() => {
                            console.log("Dispatching Published")
                            dispatch(publishedExamRequest(data?.id))
                        }} />
                ),
                restrict: (
                    userRole != 'Teacher' ? <Button
                        onClick={() => dispatch(restrictExamRequest(data.id))}
                        isLoading={data?.id == selected?.id ? restrictLoading : false}
                        bg='main_1'
                        color='white'
                    >Restrict absent students
                    </Button> : <></>
                ),
                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {
                            setAdd(false);
                            dispatch(openCreateExamDataModel())
                        }}
                    />
                ),
                delete: (<IconButton
                    variant='outline'
                    border='0'
                    color='gray.600'
                    aria-label='Call Sage'
                    fontSize='20px'
                    icon={<RiDeleteBinLine color='red' />}
                    onClick={() => dispatch(deleteExamRequest(data?.id))}
                />)
            };
        })
    const columns = [
        {
            Header: ' الاختبار',
            accessor: 'title'
        },
        {
            Header: 'المادة',
            accessor: 'subject.name_ar'
        },
        {
            Header: ' نشر الاجابات', 
            accessor: 'publish'
        },
        {
            Header: 'تفعيل الاختبار',
            accessor: 'enable'
        },
        {
            Header: 'تفعيل الاعادة',
            accessor: 'redo'
        },
        {
            Header: 'نشر الاختبار',
            accessor: 'publish_exam'
        },
        {
            Header: userRole != 'Teacher' ? '  ايقاف الطلاب  ' : "",
            accessor: 'restrict'

        },
        {
            Header: 'تعديل',
            accessor: 'edit'
        },
        {
            Header: 'حذف',
            accessor: 'delete'
        }
    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(exams?.length / 10)}

        />
    )
}

export default ExamsTable