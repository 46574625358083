import Types from "./Types";

const INITIAL_STATE = {
  exams: [],
  examQuestions: [],
  studentExams: [],
  exam: {},
  isLoading: false,
  publishLoading: false,
  restrictLoading: false,
  enableLoading: false,
  count: "",
};

export default function exams(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_EXAMS_SUCCESS: {
      return {
        ...state,
        exams: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_ALL_EXAM_QUESTIONS_SUCCESS: {
      console.log(payload);
      return {
        ...state,
        examQuestions: payload.data,
      };
    }

    case Types.GET_EXAMS_SUPER_ADMIN_SUCCESS: {
      return {
        ...state,
        exams: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_ADMIN_STUDENT_EXAMS_SUCCESS: {
      return {
        ...state,
        studentExams: payload.data,
        count: payload.data.length,
      };
    }

    case Types.RESTRICT_EXAM_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ENABLE_EXAM_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_EXAM_SUCCESS: {
      return {
        ...state,
        exam: payload,
      };
    }

    case Types.EDIT_EXAM_SUCCESS: {
      let data = payload.data;
      const exams = state.exams;
      let idx = 0;
      exams.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      exams[idx] = data;
      return {
        ...state,
        exams: exams,
      };
    }

    // create
    case Types.CREATE_EXAM_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.exams.slice();
      let index = state.exams.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        exams: newArray,
      };
    }
    case Types.CREATE_EXAM_QUESTIONS_SUCCESS: {
      const data = payload.data.data;

      return {
        ...state,
      };
    }
    case Types.ADD_EXAMS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_EXAM_SUCCESS: {
      const newexams = state.exams.filter((item) => item.id !== payload);
      return {
        ...state,
        exams: newexams,
      };
    }

    case Types.PUBLISH_EXAM_LOADING: {
      return {
        ...state,
        publishLoading: payload,
      };
    }
    case Types.RESTRICT_EXAM_LOADING: {
      return {
        ...state,
        restrictLoading: payload,
      };
    }

    case Types.ENABLE_EXAM_LOADING: {
      return {
        ...state,
        enableLoading: payload,
      };
    }

    case Types.PUBLISH_EXAM_SUCCESS: {
      let id = payload.payload;
      let data = payload.result.data.data;
      const exams = state.exams;
      let idx = 0;
      exams.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });

      exams[idx].auto_publish_result = data?.auto_publish_result;
      return {
        ...state,
        exams: exams,
      };
    }
    case Types.EDIT_EXAM_QUESTION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_BULK_CORRECTION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_MANUAL_EXAMS_SUCCESS: {
      return {
        ...state,
        exams: payload.data,
      };
    }
    case Types.GET_MANUAL_EXAMS_MARKS_SUCCESS: {
      return {
        ...state,
        marks: payload.data,
      };
    }
    case Types.SET_MANUAL_EXAM_MARK_SUCCESS: {
      return {
        ...state,
      };
    }

    case Types.PUBLISHED_EXAM_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.ENABLE_REDO_REQUEST: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
