import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getLiveVideos({ payload }) {
  try {
    yield put(actions.addLiveVideoLoading());
    const result = yield call(api.getLiveVideos);
    yield put(actions.getLiveVideosSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getLiveVideosFail());
  } finally {
    yield put(actions.addLiveVideoLoading(false));
  }
}

// create
function* createLiveVideo(formData) {
  try {
    yield put(actions.addLiveVideoLoading());
    const result = yield call(api.createLiveVideo, formData.payload);
    yield put(
      actions.createLiveVideoSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createLiveVideoFail());
  } finally {
    yield put(actions.addLiveVideoLoading(false));
  }
}

function* livevideoReport({ payload }) {
  try {
    yield put(actions.addLiveVideoLoading());
    const result = yield call(api.livevideoReport, payload);
    yield put(
      actions.livevideoReportSuccess({
        data: result.data?.data,
      })
    );
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.livevideoReportFail());
  } finally {
    yield put(actions.addLiveVideoLoading(false));
  }
}
export default function* LivevideoSaga() {
  yield takeLatest(Types.GET_LIVE_VIDEOS_REQUEST, getLiveVideos);
  yield takeLatest(Types.CREATE_LIVE_VIDEO_REQUEST, createLiveVideo);
  yield takeLatest(Types.LIVE_VIDEO_REPORT_REQUEST, livevideoReport);
}
