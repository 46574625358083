import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddVideo from "../../../containers/admin/videos/models/add-video";
import AddVideoFiels from "../../../containers/admin/videos/models/add-video-fiels";
import SearchBar from "../../../containers/admin/videos/searchbar";
import VideosTable from "../../../containers/admin/videos/table";
import { getAllVideosRequest } from "../../../modules/admin-modules/videos/Actions";
import { Button, Spinner } from "@chakra-ui/react";
import { fixViewsCountRequest } from "../../../modules/admin-modules/videos/Actions";

const Videos = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const user = useSelector((state) => state.user.userData);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllVideosRequest());
  }, []);

  const fixViewsCount = () => {
    dispatch(fixViewsCountRequest());
  }

  const isSuperAdmin = user?.role && Array.isArray(user.role) && user.role[0]?.name === "Super Admin";

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      {isSuperAdmin && (
        <Button 
          onClick={fixViewsCount}
          colorScheme="yellow"
          size="sm"
          mb={4}
        >
          Fix Views Count
        </Button>
      )}
      <VideosTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddVideo
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
      <AddVideoFiels isAdd={isAdd} data={selectedData} />
    </Layout>
  );
};

export default Videos;
