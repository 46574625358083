import Types from "./Types";

const INITIAL_STATE = {
  liveVideos: [],
  liveVideosReport: [],
  isLoading: false,
  count: "",
};

export default function adminLiveVideos(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_LIVE_VIDEOS_SUCCESS: {
      return {
        ...state,
        liveVideos: payload.data,
        count: payload.data.length,
      };
    }

    // create
    case Types.CREATE_LIVE_VIDEO_SUCCESS: {
      const data = payload?.data?.data;
      let newArray = [...state.liveVideos, data];
      return {
        ...state,
        liveVideos: newArray,
      };
    }

    case Types.LIVE_VIDEO_REPORT_SUCCESS: {
      return {
        ...state,
        liveVideosReport: payload.data,
      };
    }

    case Types.ADD_LIVE_VIDEOS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
