import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";
import {
  createHomeworkRequest,
  editHomeworkRequest,
} from "../../../../modules/admin-modules/homework/Actions";
import { closeAddHomeworkModel } from "../../../../modules/admin-modules/models/Actions";
import { getAllVideosRequest } from "../../../../modules/admin-modules/videos/Actions";
const AddHomework = ({ isAdd, data, setSelectedData }) => {
  const openVideo = useSelector((state) => state.model.isOpen22);
  const isLoading = useSelector((state) => state.videos.isLoading);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? { ...data, title: data?.title } : {},
    shouldUnregister: true,
  });

  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const videos = useSelector((state) => state.videos.videos);

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const videosOptions = videos?.map((data) => {
    return { value: data.id, label: data.title };
  });

  useEffect(() => {
    if (openVideo) {
      if (chapters.length == 0) dispatch(getAllChaptersRequest());
      if (groups.length == 0) dispatch(getAllGroupsRequest());
      if (videos.length == 0) dispatch(getAllVideosRequest());
    }
  }, [openVideo]);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
      borderColor: errors?.chapter_id?.message ? "red" : "gray.200",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "اضافة واجب " : "تعديل واجب"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    const action = (data) => {
      dispatch(closeAddHomeworkModel());
      reset();
      setSelectedData(() => { });
    };
    if (isAdd) {
      let formData = await new FormData();
      for (let key in values) {
        if (key == "groups") {
          values[key]?.map((val, idx) =>
            formData.append(`groups[${idx}]`, val)
          );
        } else {
          formData.append(key, values[key]);
        }
      }
      await dispatch(createHomeworkRequest(formData, action));
    } else {
      let formData = await new FormData();
      formData.append("_method", "PUT");
      const totel = { ...data, ...values };
      for (let key in totel) {
        if (key == "groups") {
          totel[key]?.map((val, idx) =>
            formData.append(`groups[${idx}]`, val?.id ? val?.id : val)
          );
        } else {
          formData.append(key, totel[key]);
        }
      }
      dispatch(
        editHomeworkRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeAddHomeworkModel());
    setSelectedData(() => { });
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openVideo}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="5"
        >
          <Box w="45%">
            <MainInput
              label=" عنوان الواجب"
              defaultValues={data?.name}
              error={errors?.name?.message}
              placeholder="  اكتب عنوان الواجب"
              register={register}
              name="name"
            />
          </Box>

          <Box w="45%">
            <FormLabel> الملاحظات : </FormLabel>
            <Textarea
              bg="#f5f5f5"
              defaultValue={data?.homework}
              placeholder="اكتب الملاحظات"
              {...register("homework", { required: "هذه المعلومات مطلوبة" })}
              name="homework"
            ></Textarea>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>الفصول : </FormLabel>
              <Select
                options={chaptersOptions}
                defaultValue={{
                  label: data?.chapter?.name,
                  value: data?.chapter?.id,
                }}
                onChange={(data) => setValue("chapter_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
              />
            </Box>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>المجموعات : </FormLabel>
              <Select
                options={groupsOptions}
                isMulti={true}
                defaultValue={data?.groups?.map((val) => {
                  return { label: val?.name, value: val?.id };
                })}
                onChange={(data) => {
                  const selectedGroups = data.map((val) => val.value);
                  setValue("groups", selectedGroups);
                }}
                styles={colourStyles}
                placeholder="المجموعات"
              />
            </Box>
          </Box>

          <Box w="45%">
            <Box w="45%">
              <FormLabel>نشر الواجب : </FormLabel>
              <Stack
                direction="row"
                role="group"
                aria-labelledby="my-radio-group"
              >
                <label>
                  <input
                    {...register("enable", { required: true })}
                    type="radio"
                    value="0"
                    defaultChecked={data?.enable == 0 ? true : false}
                  />
                  عدم نشر
                </label>
                <label>
                  <input
                    {...register("enable", { required: true })}
                    type="radio"
                    value="1"
                    defaultChecked={data?.enable == 1 ? true : false}
                  />
                  نشر
                </label>
              </Stack>
            </Box>
          </Box>
          <Box w="45%">
            <Box>
              <FormLabel>تفعيل الواجب :  </FormLabel>
              <Stack
                direction="row"
                role="group"
                aria-labelledby="my-radio-group"
              >
                <label>
                  <input
                    {...register("active", { required: true })}
                    type="radio"
                    value="0"
                    defaultChecked={data?.active === 0 ? true : false}
                  />
                  عدم التفعيل
                </label>
                <label>
                  <input
                    {...register("active", { required: true })}
                    type="radio"
                    value="1"
                    defaultChecked={data?.active === 1 ? true : false}
                  />
                  تفعيل
                </label>
              </Stack>
            </Box>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>الفيديو : </FormLabel>
              <Select
                options={videosOptions}
                defaultValue={{
                  label: data?.video?.title,
                  value: data?.video?.id,
                }}
                onChange={(data) => setValue("video_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
              />
            </Box>
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddHomework;
