import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  SkeletonText,
  Stack,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { RiCalendarTodoFill, RiTimerLine, RiTvLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Video } from "lucide-react";
import { openAddVoucherModel } from "../../../modules/admin-modules/models/Actions";
import { moreVideoViewsRequest } from "../../../modules/students-modules/videos/Actions";
import { countSeens } from "./func";

const VideoCard = ({ data, index, selectedindex, handelClick, isSelected }) => {
  const date1 = dayjs(data?.expiry_date);
  const diff = date1.diff(new Date(), "day");
  const seenLoading = useSelector((state) => state.chapterVideos.seenLoading);
  const voucherLoading = useSelector((state) => state.chapterVideos.voucherLoading);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  return (
    <Stack
      bg={isSelected ? "gray.100" : "transparent"}
      borderRadius="8px"
      p={{ base: "10px", md: "12px" }}
      color="main_1"
      w="100%"
      minH={{ base: "auto", md: "100px" }}
      alignItems="flex-start"
      gap="8px"
      cursor="pointer"
      transition="all 0.2s"
      _hover={{ bg: "gray.100" }}
      borderBottom="1px solid"
      borderColor="gray.200"
      onClick={() => {
        if (data?.suspended != 1) {
          countSeens(dispatch, data);
        }
        handelClick && handelClick();
      }}
    >
      <Flex w="100%" gap="12px" alignItems="flex-start">
        <Box position="relative" flexShrink={0}>
          <Video 
            size={32} 
            style={{
              background: '#f0f0f0',
              padding: '4px',
              borderRadius: '4px'
            }}
          />
          {isSelected && (
            <Box
              position="absolute"
              left="-2px"
              top="50%"
              transform="translateY(-50%)"
              w="4px"
              h="80%"
              bg="main_1"
              borderRadius="0 4px 4px 0"
            />
          )}
        </Box>
        
        <VStack align="flex-start" flex="1" spacing="4px">
          <Text 
            fontSize={{ base: "13px", md: "14px" }} 
            fontWeight="500" 
            noOfLines={2}
            lineHeight="1.4"
          >
            {data?.title}
          </Text>
          
          {seenLoading && index == selectedindex ? (
            <SkeletonText
              startColor="gray.100"
              endColor="gray.600"
              w="100%"
              noOfLines={2}
              spacing="4"
            />
          ) : (
            <VStack w="100%" fontSize="11px" alignItems="flex-start" spacing="2px">
              <HStack spacing="6px" color="gray.600">
                <Icon as={RiCalendarTodoFill} fontSize="11px" />
                <Text>{dayjs(data?.created_at).format("D MMM, YYYY")}</Text>
              </HStack>
              
              {diff && userData?.subject?.id != "34" && (
                <HStack spacing="6px" color="gray.600">
                  <Icon as={RiTimerLine} fontSize="11px" />
                  <Text>{diff > 0 ? diff : 0} Days remaining</Text>
                </HStack>
              )}
              
              {data?.max_allowed_views && userData?.subject?.id != "34" && (
                <Text 
                  color={data?.views_count?.views_count >= 4 ? "red.500" : "gray.600"}
                  fontSize="11px"
                  mt="2px !important"
                >
                  {data?.views_count?.views_count === 4 
                    ? "You have consumed the maximum allowed watch duration" 
                    : data?.views_count?.views_count === 3 
                    ? "Please contact technical support" 
                    : data?.suspended === 1 
                    ? "Video suspended - submit homework or take exam" 
                    : ""}
                </Text>
              )}
            </VStack>
          )}
        </VStack>
      </Flex>

      {(data?.max_allowed_views <= data?.views_count?.views_count ||
        (data?.suspended == 1 && data?.user_video_suspended != 0)) && (
        <Stack 
          w="100%" 
          direction={{ base: "column", sm: "row" }} 
          spacing="2"
          mt="2px !important"
        >
          <Button
            bg="main_1"
            color="white"
            size="md"
            flex="1"
            onClick={(e) => {
              e.stopPropagation();
              let formData = new FormData();
              formData.append("video_id", data?.id);
              dispatch(moreVideoViewsRequest(formData));
            }}
          >
            Request Voucher
          </Button>
          <Button
            border="1px solid"
            color="main_1"
            bg="white"
            size="md"
            flex="1"
            isLoading={voucherLoading}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(openAddVoucherModel(data?.id));
            }}
          >
            Add Voucher
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default VideoCard;