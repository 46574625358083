import axiosInstance from "../../../helpers/api";

export const getLiveVideos = (body) => {
  return axiosInstance.get("/admin/live_videos", body);
};

export const createLiveVideo = (formData) => {
  return axiosInstance.post("/admin/live_videos", formData);
};

export const livevideoReport = (id) => {
  return axiosInstance.get(`/admin/live_videos/${id}`);
};
