import Types from "./Types";

const INITIAL_STATE = {
  studentPerGroup: [],
  performance: {},
  videosReport: [],
  homeworkUncomment: [],
  attendancesReport: [],
  examReport: [],
  unpaidReport: [],
  paidReport: [],
  examAttendance: [],
  homeworkAttendance: [],
  homeworkAbsent: [],
  isLoading: false,
};

export default function reports(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.STUDENTS_PER_GROUP_SUCCESS: {
      return {
        ...state,
        studentPerGroup: payload.data,
      };
    }

    case Types.VIDEO_REPORT_SUCCESS: {
      return {
        ...state,
        videosReport: payload.data,
      };
    }

    case Types.UNPAID_REPORT_SUCCESS: {
      return {
        ...state,
        unpaidReport: payload.data.data,
      };
    }
    case Types.PAID_REPORT_SUCCESS: {
      return {
        ...state,
        paidReport: payload.data.data,
      };
    }
    case Types.ATTENDANCE_REPORT_SUCCESS: {
      return {
        ...state,
        attendancesReport: payload.data.data,
      };
    }

    // create
    case Types.EXAM_REPORT_SUCCESS: {
      return {
        ...state,
        examReport: payload.data?.data,
      };
    }
    case Types.EXAM_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        examAttendance: payload.data,
      };
    }

    case Types.HOMEWORK_ATTENDENCE_REPORT_SUCCESS: {
      return {
        ...state,
        homeworkAttendance: payload.data?.data,
      };
    }
    case Types.HOMEWORK_UNCOMMENT_REPORT_SUCCESS: {
      return {
        ...state,
        homeworkUncomment: payload.data?.data,
      };
    }
    case Types.FILTER_HOMEWORK_ATTENDENCE_REPORT_SUCCESS: {
      return {
        ...state,
        homeworkAttendance: payload.data?.data,
      };
    }
    case Types.HOMEWORK_ABSENT_REPORT_SUCCESS: {
      return {
        ...state,
        homeworkAbsent: payload.data,
      };
    }

    case Types.DELETE_STUDENT_HOMEWORK_SUCCESS: {
      //console.log(payload);
      const newhomeworks = state.homeworkUncomment.filter(
        (item) => item.student_id !== payload.student_id
      );
      return {
        ...state,
        homeworkUncomment: newhomeworks,
      };
    }
    case Types.RESTRICT_STUDENT_HOMEWORK_REQUEST: {
      return {
        ...state,
      };
    }
    case Types.ACCEPT_STUDENT_HOMEWORK_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.COMMENT_STUDENT_HOMEWORK_SUCCESS: {
      const newhomeworks = state.homeworkUncomment.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        homeworkUncomment: newhomeworks,
      };
    }
    case Types.STUDENT_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        performance: payload.data,
      };
    }

    case Types.REPORT_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.CENTER_HW_SUCCESS: {
      return {
        ...state,
        centerHw: payload.data,
      };
    }

    default: {
      return state;
    }
  }
}
