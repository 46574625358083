import { Box, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CTable from "../../../components/Table/table";
import { openAddLiveVideo } from "../../../modules/admin-modules/models/Actions";
import { deleteAdminSectionRequest } from "../../../modules/admin-modules/sections/Actions";

const LiveVideosTable = ({ setAdd, setSelectedData }) => {
  const liveVideos = useSelector((state) => state.adminLiveVideos.liveVideos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.adminLiveVideos.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = liveVideos?.map((data) => {
    return {
      ...data,
      id: data?.id,
      name: data?.title,
      link: (
        <a
          href={data?.zoom_link}
          target="blank"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          {data?.zoom_link}
        </a>
      ),
      report: (
        <Box
          cursor="pointer"
          onClick={() => navigate(`/dashboard/live-videos-report/${data?.id}`)}
        >
          <TbReportAnalytics />
        </Box>
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openAddLiveVideo());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(deleteAdminSectionRequest(data?.id))}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " الفيديو",
      accessor: "name",
    },
    {
      Header: " رابط الفيديو",
      accessor: "link",
    },
    {
      Header: "Video Report",
      accessor: "report",
    },
    // {
    //   Header: "حذف",
    //   accessor: "delete",
    // },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(liveVideos?.length / 10)}
    />
  );
};

export default LiveVideosTable;
