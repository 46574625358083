import Types from "./Types";

export const studentsPerGroupRequest = (id) => ({
  type: Types.STUDENTS_PER_GROUP_REQUEST,
  payload: id,
});

export const studentsPerGroupSuccess = (id) => ({
  type: Types.STUDENTS_PER_GROUP_SUCCESS,
  payload: id,
});

export const studentsPerGroupFail = () => ({
  type: Types.STUDENTS_PER_GROUP_FAIL,
});

export const videoReportRequest = (id) => ({
  type: Types.VIDEO_REPORT_REQUEST,
  payload: id,
});

export const videoReportSuccess = (id) => ({
  type: Types.VIDEO_REPORT_SUCCESS,
  payload: id,
});

export const videoReportFail = () => ({
  type: Types.VIDEO_REPORT_FAIL,
});

export const attendanceReportRequest = (lecId, date) => ({
  type: Types.ATTENDANCE_REPORT_REQUEST,
  lecId: lecId,
  date: date,
});

export const attendanceReportSuccess = (id) => ({
  type: Types.ATTENDANCE_REPORT_SUCCESS,
  payload: id,
});

export const attendanceReportFail = () => ({
  type: Types.ATTENDANCE_REPORT_FAIL,
});

export const examReportRequest = (id) => ({
  type: Types.EXAM_REPORT_REQUEST,
  payload: id,
});

export const examReportSuccess = (id) => ({
  type: Types.EXAM_REPORT_SUCCESS,
  payload: id,
});

export const examReportFail = () => ({
  type: Types.EXAM_REPORT_FAIL,
});

export const examAttendaceRequest = (id) => ({
  type: Types.EXAM_ATTENDANCE_REQUEST,
  payload: id,
});

export const examAttendaceSuccess = (id) => ({
  type: Types.EXAM_ATTENDANCE_SUCCESS,
  payload: id,
});

export const examAttendaceFail = () => ({
  type: Types.EXAM_ATTENDANCE_FAIL,
});

export const unpaidReportRequest = (id) => ({
  type: Types.UNPAID_REPORT_REQUEST,
  payload: id,
});

export const unpaidReportSuccess = (id) => ({
  type: Types.UNPAID_REPORT_SUCCESS,
  payload: id,
});

export const unpaidReportFail = () => ({
  type: Types.UNPAID_REPORT_FAIL,
});

export const paidReportRequest = (id) => ({
  type: Types.PAID_REPORT_REQUEST,
  payload: id,
});

export const paidReportSuccess = (id) => ({
  type: Types.PAID_REPORT_SUCCESS,
  payload: id,
});

export const paidReportFail = () => ({
  type: Types.PAID_REPORT_FAIL,
});
export const reportLoading = (isLoading) => ({
  type: Types.REPORT_LOADING,
  payload: isLoading,
});

export const homeworkAttendenceReportRequest = (id) => ({
  type: Types.HOMEWORK_ATTENDENCE_REPORT_REQUEST,
  payload: id,
});

export const homeworkAttendenceReportSuccess = (id) => ({
  type: Types.HOMEWORK_ATTENDENCE_REPORT_SUCCESS,
  payload: id,
});

export const homeworkAttendenceReportFail = () => ({
  type: Types.HOMEWORK_ATTENDENCE_REPORT_FAIL,
});

export const homeworkUncommentReportRequest = (id) => ({
  type: Types.HOMEWORK_UNCOMMENT_REPORT_REQUEST,
  payload: id,
});

export const homeworkUncommentReportSuccess = (id) => ({
  type: Types.HOMEWORK_UNCOMMENT_REPORT_SUCCESS,
  payload: id,
});

export const homeworkUncommentReportFail = () => ({
  type: Types.HOMEWORK_UNCOMMENT_REPORT_FAIL,
});

export const filterHomeworkAttendenceReportRequest = (id) => ({
  type: Types.FILTER_HOMEWORK_ATTENDENCE_REPORT_REQUEST,
  payload: id,
});

export const filterHomeworkAttendenceReportSuccess = (id) => ({
  type: Types.FILTER_HOMEWORK_ATTENDENCE_REPORT_SUCCESS,
  payload: id,
});

export const filterHomeworkAttendenceReportFail = () => ({
  type: Types.FILTER_HOMEWORK_ATTENDENCE_REPORT_FAIL,
});
export const homeworkAbsentReportRequest = (id) => ({
  type: Types.HOMEWORK_ABSENT_REPORT_REQUEST,
  payload: id,
});

export const homeworkAbsentReportSuccess = (id) => ({
  type: Types.HOMEWORK_ABSENT_REPORT_SUCCESS,
  payload: id,
});

export const homeworkAbsentReportFail = () => ({
  type: Types.HOMEWORK_ABSENT_REPORT_FAIL,
});

//delete student homework

export const deleteStuentHomeworkRequest = (student_id, homework_id) => ({
  type: Types.DELETE_STUDENT_HOMEWORK_REQUEST,
  payload: { student_id, homework_id },
});

export const deleteStuentHomeworkSuccess = (data) => ({
  type: Types.DELETE_STUDENT_HOMEWORK_SUCCESS,
  payload: data,
});

export const deleteStuentHomeworkFail = () => ({
  type: Types.DELETE_STUDENT_HOMEWORK_FAIL,
});

//assign stuent homework to restrict group

export const restrictStuentHomeworkRequest = (id) => ({
  type: Types.RESTRICT_STUDENT_HOMEWORK_REQUEST,
  payload: id,
});

export const restrictStuentHomeworkSuccess = (id) => ({
  type: Types.RESTRICT_STUDENT_HOMEWORK_SUCCESS,
  payload: id,
});

export const restrictStuentHomeworkFail = () => ({
  type: Types.RESTRICT_STUDENT_HOMEWORK_FAIL,
});

export const acceptStuentHomeworkRequest = (id) => ({
  type: Types.ACCEPT_STUDENT_HOMEWORK_REQUEST,
  payload: id,
});

export const acceptStuentHomeworkSuccess = (id) => ({
  type: Types.ACCEPT_STUDENT_HOMEWORK_SUCCESS,
  payload: id,
});

export const acceptStuentHomeworkFail = () => ({
  type: Types.ACCEPT_STUDENT_HOMEWORK_FAIL,
});

export const commentStuentHomeworkRequest = (payload) => ({
  type: Types.COMMENT_STUDENT_HOMEWORK_REQUEST,
  payload,
});

export const commentStuentHomeworkSuccess = (id) => ({
  type: Types.COMMENT_STUDENT_HOMEWORK_SUCCESS,
  payload: id,
});

export const commentStuentHomeworkFail = () => ({
  type: Types.COMMENT_STUDENT_HOMEWORK_FAIL,
});

export const studentPerformanceRequest = (payload) => ({
  type: Types.STUDENT_PERFORMANCE_REQUEST,
  payload,
});

export const studentPerformanceSuccess = (id) => ({
  type: Types.STUDENT_PERFORMANCE_SUCCESS,
  payload: id,
});

export const studentPerformanceFail = () => ({
  type: Types.STUDENT_PERFORMANCE_FAIL,
});

export const centerHwRequest = (payload) => ({
  type: Types.CENTER_HW_REQUEST,
  payload,
});

export const centerHwSuccess = (id) => ({
  type: Types.CENTER_HW_SUCCESS,
  payload: id,
});

export const centerHwFail = () => ({
  type: Types.CENTER_HW_FAIL,
});
