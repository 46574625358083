import Types from "./Types";

export const getAllExamsRequest = (body) => ({
  type: Types.GET_EXAMS_REQUEST,
  payload: body,
});

export const getAllExamsSuccess = (data) => ({
  type: Types.GET_EXAMS_SUCCESS,
  payload: data,
});

export const getAllExamsFail = () => ({
  type: Types.GET_EXAMS_FAIL,
});

export const getAllExamQuestionsRequest = (id) => ({
  type: Types.GET_ALL_EXAM_QUESTIONS_REQUEST,
  payload: id,
});

export const getAllExamQuestionsSuccess = (payload) => ({
  type: Types.GET_ALL_EXAM_QUESTIONS_SUCCESS,
  payload,
});

export const getAllExamQuestionsFail = () => ({
  type: Types.GET_ALL_EXAM_QUESTIONS_FAIL,
});

export const getAllExamsSuperAdminRequest = (body) => ({
  type: Types.GET_EXAMS_SUPER_ADMIN_REQUEST,
  payload: body,
});

export const getAllExamsSuperAdminSuccess = (data) => ({
  type: Types.GET_EXAMS_SUPER_ADMIN_SUCCESS,
  payload: data,
});

export const getAllExamsSuperAdminFail = () => ({
  type: Types.GET_EXAMS_SUPER_ADMIN_FAIL,
});

export const getStudentExamsRequest = (body) => ({
  type: Types.GET_ADMIN_STUDENT_EXAMS_REQUEST,
  payload: body,
});

export const getStudentExamsSuccess = (data) => ({
  type: Types.GET_ADMIN_STUDENT_EXAMS_SUCCESS,
  payload: data,
});

export const getStudentExamsFail = () => ({
  type: Types.GET_ADMIN_STUDENT_EXAMS_FAIL,
});

export const editExamRequest = (formData, action) => ({
  type: Types.EDIT_EXAM_REQUEST,
  payload: formData,
  action: action,
});

export const editExamSuccess = (data) => ({
  type: Types.EDIT_EXAM_SUCCESS,
  payload: data,
});

export const editExamFail = () => ({
  type: Types.EDIT_EXAM_FAIL,
});

//LOADING
export const addExamLoading = (isLoading) => ({
  type: Types.ADD_EXAMS_LOADING,
  payload: isLoading,
});

// Create Exam
export const createExamRequest = (formData, action) => ({
  type: Types.CREATE_EXAM_REQUEST,
  payload: formData,
  action: action,
});

export const createExamSuccess = ({ data }) => ({
  type: Types.CREATE_EXAM_SUCCESS,
  payload: {
    data,
  },
});

export const createExamFail = () => ({
  type: Types.CREATE_EXAM_FAIL,
});

// Create Exam
export const createExamQuestionsRequest = (formData, action) => ({
  type: Types.CREATE_EXAM_QUESTIONS_REQUEST,
  payload: formData,
  action: action,
});

export const createExamQuestionsSuccess = ({ data }) => ({
  type: Types.CREATE_EXAM_QUESTIONS_SUCCESS,
  payload: {
    data,
  },
});

export const createExamQuestionsFail = () => ({
  type: Types.CREATE_EXAM_QUESTIONS_FAIL,
});

export const deleteExamRequest = (id) => ({
  type: Types.DELETE_EXAM_REQUEST,
  payload: id,
});

export const deleteExamSuccess = (id) => ({
  type: Types.DELETE_EXAM_SUCCESS,
  payload: id,
});

export const deleteExamFail = () => ({
  type: Types.DELETE_EXAM_FAIL,
});

export const publishExamRequest = (id) => ({
  type: Types.PUBLISH_EXAM_REQUEST,
  payload: id,
});

export const publishExamSuccess = (id) => ({
  type: Types.PUBLISH_EXAM_SUCCESS,
  payload: id,
});

export const publishExamFail = () => ({
  type: Types.PUBLISH_EXAM_FAIL,
});

export const publishedExamRequest = (id) => ({
  type: Types.PUBLISHED_EXAM_REQUEST,
  payload: id,
});

export const publishedExamSuccess = (id) => ({
  type: Types.PUBLISHED_EXAM_SUCCESS,
  payload: id,
});

export const publishedExamFail = () => ({
  type: Types.PUBLISHED_EXAM_FAIL,
});

export const enableRedoRequest = (id) => ({
  type: Types.ENABLE_REDO_REQUEST,
  payload: id,
});

export const enableRedoSuccess = (id) => ({
  type: Types.ENABLE_REDO_SUCCESS,
  payload: id,
});

export const enableRedoFail = () => ({
  type: Types.ENABLE_REDO_FAIL,
});

export const restrictExamRequest = (id) => ({
  type: Types.RESTRICT_EXAM_REQUEST,
  payload: id,
});

export const restrictExamSuccess = (id) => ({
  type: Types.RESTRICT_EXAM_SUCCESS,
  payload: id,
});

export const restrictExamFail = () => ({
  type: Types.RESTRICT_EXAM_FAIL,
});
export const enableExamRequest = (id) => ({
  type: Types.ENABLE_EXAM_REQUEST,
  payload: id,
});

export const enableExamSuccess = (id) => ({
  type: Types.ENABLE_EXAM_SUCCESS,
  payload: id,
});

export const enableExamFail = () => ({
  type: Types.ENABLE_EXAM_FAIL,
});
export const publishExamLoading = (isLoading) => ({
  type: Types.PUBLISH_EXAM_LOADING,
  payload: isLoading,
});
export const publishedExamLoading = (isLoading) => ({
  type: Types.PUBLISH_EXAM_LOADING,
  payload: isLoading,
});
export const enableRedoLoading = (isLoading) => ({
  type: Types.PUBLISH_EXAM_LOADING,
  payload: isLoading,
});
export const enableExamLoading = (isLoading) => ({
  type: Types.ENABLE_EXAM_LOADING,
  payload: isLoading,
});
export const restrictExamLoading = (isLoading) => ({
  type: Types.RESTRICT_EXAM_LOADING,
  payload: isLoading,
});

export const editExamQuestionRequest = (formData, action) => ({
  type: Types.EDIT_EXAM_QUESTION_REQUEST,
  payload: formData,
  action: action,
});

export const editExamQuestionSuccess = (data) => ({
  type: Types.EDIT_EXAM_QUESTION_SUCCESS,
  payload: data,
});

export const editExamQuestionFail = () => ({
  type: Types.EDIT_EXAM_QUESTION_FAIL,
});

export const addBulkCorrectionRequest = (formData) => ({
  type: Types.ADD_BULK_CORRECTION_REQUEST,
  payload: formData,
});

export const addBulkCorrectionSuccess = (id) => ({
  type: Types.ADD_BULK_CORRECTION_SUCCESS,
  payload: id,
});

export const addBulkCorrectionFail = () => ({
  type: Types.ADD_BULK_CORRECTION_FAIL,
});

export const getManualExamsRequest = (body) => ({
  type: Types.GET_MANUAL_EXAMS_REQUEST,
  payload: body,
});

export const getManualExamsSuccess = (data) => ({
  type: Types.GET_MANUAL_EXAMS_SUCCESS,
  payload: data,
});

export const getManualExamsFail = () => ({
  type: Types.GET_MANUAL_EXAMS_FAIL,
});

export const getManualExamsMarksRequest = (body) => ({
  type: Types.GET_MANUAL_EXAMS_MARKS_REQUEST,
  payload: body,
});

export const getManualExamsMarksSuccess = (data) => ({
  type: Types.GET_MANUAL_EXAMS_MARKS_SUCCESS,
  payload: data,
});

export const getManualExamsMarksFail = () => ({
  type: Types.GET_MANUAL_EXAMS_MARKS_FAIL,
});

export const setManualExamMarkRequest = (formData) => ({
  type: Types.SET_MANUAL_EXAM_MARK_REQUEST,
  payload: formData,
});

export const setManualExamMarkSuccess = (data) => ({
  type: Types.SET_MANUAL_EXAM_MARK_SUCCESS,
  payload: data,
});

export const setManualExamMarkFail = () => ({
  type: Types.SET_MANUAL_EXAM_MARK_FAIL,
});
