export default {
  //get all CHAPTERs
  GET_LIVE_VIDEOS_REQUEST: "GET_LIVE_VIDEOS_REQUEST",
  GET_LIVE_VIDEOS_SUCCESS: "GET_LIVE_VIDEOS_SUCCESS",
  GET_LIVE_VIDEOS_FAIL: "GET_LIVE_VIDEOS_FAIL",

  //create CHAPTER
  CREATE_LIVE_VIDEO_REQUEST: "CREATE_LIVE_VIDEO_REQUEST",
  CREATE_LIVE_VIDEO_SUCCESS: "CREATE_LIVE_VIDEO_SUCCESS",
  CREATE_LIVE_VIDEO_FAIL: "CREATE_LIVE_VIDEO_FAIL",

  LIVE_VIDEO_REPORT_REQUEST: "LIVE_VIDEO_REPORT_REQUEST",
  LIVE_VIDEO_REPORT_SUCCESS: "LIVE_VIDEO_REPORT_SUCCESS",
  LIVE_VIDEO_REPORT_FAIL: "LIVE_VIDEO_REPORT_FAIL",
  //loading
  ADD_LIVE_VIDEOS_LOADING: "ADD_LIVE_VIDEOS_LOADING",
};
