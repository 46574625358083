import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* studentsPerGroup({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.studentsPerGroup, payload);
    yield put(actions.studentsPerGroupSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.reportLoading(false));
    yield put(actions.studentsPerGroupFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

//Edit Tables
function* videoReport({ payload }) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.videoReport, payload);
    yield put(actions.videoReportSuccess(result.data));
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.videoReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

// create
function* attendanceReport(formData) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.attendanceReport, formData);
    yield put(
      actions.attendanceReportSuccess({
        data: result.data,
      })
    );
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.attendanceReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* examReport({ payload }) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.examReport, payload);
    yield put(actions.examReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.examReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}
function* examAttendace({ payload }) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.examAttendace, payload);
    yield put(actions.examAttendaceSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.examAttendaceFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}
function* unpaidReport({ payload }) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.unpaidReport, payload);
    yield put(actions.unpaidReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.unpaidReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* paidReport({ payload }) {
  try {
    yield put(actions.reportLoading(true));

    const result = yield call(api.paidReport, payload);
    yield put(actions.paidReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));

    yield put(actions.paidReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* homeworkAttendace({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.homeworkAttendace, payload);
    yield put(actions.homeworkAttendenceReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.homeworkAttendenceReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* filterHomeworkAttendace({ payload }) {
  console.log(payload, "payload");
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.filterhomeworkAttendace, payload);
    yield put(actions.filterHomeworkAttendenceReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.filterHomeworkAttendenceReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* homeworkAbsent({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.homeworkAbsent, payload);
    yield put(actions.homeworkAbsentReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.homeworkAbsentReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* deleteStudentHomework({ payload }) {
  //console.log(payload);

  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.deleteStudentHomework, payload);
    yield put(actions.deleteStuentHomeworkSuccess(payload));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.deleteStuentHomeworkFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* restrictStudentHomework({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.restrictStudentHomework, payload);
    yield put(actions.restrictStuentHomeworkSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.restrictStuentHomeworkFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}
function* homeworkUncomment({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.homeworkUncomment, payload);
    yield put(actions.homeworkUncommentReportSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.homeworkUncommentReportFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}
function* acceptStudentHomework({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.acceptStudentHomework, payload);
    yield put(actions.acceptStuentHomeworkSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.acceptStuentHomeworkFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* commentStudentHomework({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.commentStudentHomework, payload);
    yield put(actions.commentStuentHomeworkSuccess(payload?.id));
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.commentStuentHomeworkFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* studentPerformance({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.studentPerformance, payload);
    yield put(actions.studentPerformanceSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.studentPerformanceFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

function* centerHW({ payload }) {
  try {
    yield put(actions.reportLoading(true));
    const result = yield call(api.centerHW, payload);
    yield put(actions.centerHwSuccess(result));
  } catch (error) {
    yield put(actions.reportLoading(false));
    yield put(actions.centerHwFail());
  } finally {
    yield put(actions.reportLoading(false));
  }
}

export default function* ReportsSaga() {
  yield takeLatest(Types.STUDENTS_PER_GROUP_REQUEST, studentsPerGroup);
  yield takeLatest(Types.VIDEO_REPORT_REQUEST, videoReport);
  yield takeLatest(Types.ATTENDANCE_REPORT_REQUEST, attendanceReport);
  yield takeLatest(Types.EXAM_REPORT_REQUEST, examReport);
  yield takeLatest(Types.EXAM_ATTENDANCE_REQUEST, examAttendace);
  yield takeLatest(Types.UNPAID_REPORT_REQUEST, unpaidReport);
  yield takeLatest(Types.PAID_REPORT_REQUEST, paidReport);

  yield takeLatest(Types.HOMEWORK_ATTENDENCE_REPORT_REQUEST, homeworkAttendace);
  yield takeLatest(Types.HOMEWORK_ABSENT_REPORT_REQUEST, homeworkAbsent);
  yield takeLatest(
    Types.DELETE_STUDENT_HOMEWORK_REQUEST,
    deleteStudentHomework
  );
  yield takeLatest(
    Types.RESTRICT_STUDENT_HOMEWORK_REQUEST,
    restrictStudentHomework
  );
  yield takeLatest(
    Types.ACCEPT_STUDENT_HOMEWORK_REQUEST,
    acceptStudentHomework
  );

  yield takeLatest(
    Types.FILTER_HOMEWORK_ATTENDENCE_REPORT_REQUEST,
    filterHomeworkAttendace
  );
  yield takeLatest(
    Types.COMMENT_STUDENT_HOMEWORK_REQUEST,
    commentStudentHomework
  );

  yield takeLatest(Types.HOMEWORK_UNCOMMENT_REPORT_REQUEST, homeworkUncomment);
  yield takeLatest(Types.STUDENT_PERFORMANCE_REQUEST, studentPerformance);
  yield takeLatest(Types.CENTER_HW_REQUEST, centerHW);
}
