import axiosInstance from "../../../helpers/api";

export const getAllExams = (body) => {
  return axiosInstance.get("/admin/exams", body);
};

export const getAllExamQuestions = (id) => {
  return axiosInstance.get(
    `/admin/bank_questions/get_questions?chapter_id=${id}`
  );
};

export const getAllExamsSuperAdmin = (body) => {
  return axiosInstance.get("/admin/exams/super_admin", body);
};

export const getStudentExams = (body) => {
  return axiosInstance.get("/admin/student_type_exams", body);
};

export const editExams = (formData) => {
  return axiosInstance.post(`/admin/exams/${formData.id}`, formData.formData);
};

export const createExams = (formData) => {
  return axiosInstance.post("/admin/exams", formData);
};

export const createExamQuestions = (formData) => {
  return axiosInstance.post("/admin/exams/createBankQuestionExams", formData);
};
export const deleteExam = (id) => {
  return axiosInstance.delete(`/admin/exams/${id}`);
};

export const publisExam = (id) => {
  return axiosInstance.get(`/admin/student_exams/${id}`);
};

export const enableExam = (id) => {
  return axiosInstance.get(`/admin/exams/enable/${id}`);
};

export const publishedExam = (id) => {
  return axiosInstance.get(`/admin/exams/publish_exam/${id}`);
};

export const enableRedo = (id) => {
  return axiosInstance.get(`/admin/exams/enable_redo/${id}`);
};

export const restrictExam = (id) => {
  return axiosInstance.put(`/admin/exams/restrict_group/${id}`);
};

export const editExamQuestion = (formData) => {
  return axiosInstance.post(
    `/admin/bank_question_answers/${formData.id}`,
    formData.formData
  );
};

export const addBulkCorrection = (formData) => {
  return axiosInstance.post(`/admin/manual_correction/bulk_insert`, formData);
};

export const getManualExams = () => {
  return axiosInstance.get("/admin/exams/list/manual_exams");
}

export const getManualExamsMarks = (id) => {
  return axiosInstance.get(`/admin/exams/manual_exam/${id}`);
}

export const setManualExamMark = (formData) => {
  return axiosInstance.post(`/admin/exams/manual_exam`, formData);
}
