import {
  Avatar,
  Box,
  Container,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { GiBookshelf } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/student/Layout/layout";
import UserData from "../../containers/student/models/user-data";
import { getAllStudentChaptersRequest } from "../../modules/students-modules/chapters/Actions";
import {
  getLiveVideoRequest,
  setLiveVideoSeenRequest,
} from "../../modules/students-modules/videos/Actions";

const StudentHome = ({ t }) => {
  const baseUrl = "https://asquera.com/asquera";
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const liveVideo = useSelector((state) => state.chapterVideos.liveVideo);

  useEffect(() => {
    dispatch(getAllStudentChaptersRequest());
    dispatch(getLiveVideoRequest());
  }, []);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Layout color="main_1" bg="white">
      <Container maxW="container.2xl" h="100%" w="100%" justifyItems={"center"}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={6}
          w="100%"
          my="80px"
        >
          {/* User Info Card */}
          <Stack
            flex="1"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            p="10px"
            boxShadow="0px 1px 5px 0px #80808066"
          >
            <Box
              bg="main_1"
              color="white"
              w="100%"
              p="10px"
              textAlign="center"
              fontSize="22px"
            >
              {`${t("Welcome")} ${userData?.name} ${t("to Asquera")}`}{" "}
            </Box>
            <HStack
              mt="0.5rem"
              w={{ base: "100%", lg: "50%" }}
              borderRadius="8px"
              p="10px"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {userData?.subject?.logo ? (
                <Avatar
                  name={userData?.subject?.name}
                  src={baseUrl + "/" + userData?.subject?.logo}
                  borderRadius="8px"
                  size="lg"
                  background="transparent"
                  h="100%"
                  w="50%"
                />
              ) : (
                <Icon as={GiBookshelf} h="100%" w="25%" />
              )}
              <VStack alignItems="flex-start" mx="7px">
                <Text color="main_1">
                  {t("Subject")} : {userData?.subject?.name}
                </Text>
                <Text color="main_1">
                  {t("Grade")} : {userData?.grade?.name}
                </Text>
                <Text color="main_1">
                  {t("Group")} : {userData?.group?.name}
                </Text>
                <Text my={4} color="main_1">
                  Code : {userData?.user_name}
                </Text>{" "}
              </VStack>
            </HStack>
            <Stack alignItems="center" my={2}>
              <QRCode
                size={200}
                value={`https://asquera.com/scan-code/${userData?.id}/attendence`}
              />
            </Stack>
          </Stack>

          {/* Live Video Section */}
          <VStack
            flex="1"
            borderRadius="8px"
            p="20px"
            boxShadow="0px 1px 5px 0px #80808066"
            alignItems="flex-start"
            justifyContent="center"
            h="fit-content"
          >
            <Text
              color="main_1"
              fontSize="20px"
              fontWeight="600"
              alignSelf="flex-start"
            >
              Live Video
            </Text>

            {liveVideo?.length == 0 ? (
              <Box
                bg="#EDEDED"
                borderRadius="8px"
                alignItems="center"
                p="25px"
                w="100%"
              >
                <Text
                  textAlign="center"
                  color="main_1"
                  fontSize="18px"
                  fontWeight="600"
                >
                  There isn't any available live videos
                </Text>
              </Box>
            ) : (
              <Button
                bg="main_1"
                color="white"
                textAlign="center"
                p="7px"
                borderRadius="8px"
                onClick={() => {
                  dispatch(setLiveVideoSeenRequest(liveVideo?.[0]?.id));
                  window.open(liveVideo?.[0]?.zoom_link);
                }}
              >
                Join {liveVideo?.[0]?.title} live video
              </Button>
            )}
          </VStack>
        </Stack>

        <UserData />
        {/* <Stack
          flexDir={{ base: "column", lg: "row" }}
          w="100%"
          borderRadius="8px"
          alignItems="flex-start"
          justifyContent="space-between"
          p="10px"
          spacing={2}
        >
          <Box
            w={{ base: "100%", lg: "60%" }}
            borderRadius="8px"
            boxShadow="0px 1px 5px 0px #80808066"
            p="10px"
          >
            <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
              {t("performanceAcrossAllExams")}
            </Text>
            <Performance
              percentage={answers["percentage"]}
              totalQuestions={answers["total_exams_questions"]}
              rightAnswers={answers["total right answers"]}
              totalExams={answers["exams_count"]}
            />
          </Box>

          <Box
            w={{ base: "100%", lg: "35%" }}
            borderRadius="8px"
            boxShadow="0px 1px 5px 0px #80808066"
            p="10px"
            h="100%"
          >
            <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
              {t("performancePerGroup")}
            </Text>
            <Performance
              percentage={perGroup["percentage"]}
              totalQuestions={perGroup["total_exams_questions"]}
              rightAnswers={perGroup["total right answers"]}
            />
          </Box>
        </Stack> */}
        {/* <Stack
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          borderRadius="8px"
          p="10px"
        >
          <Box w={{ base: "100%", lg: "60%" }} mt="0 !important">
            <Box
              w="100%"
              borderRadius="8px"
              boxShadow="0px 1px 5px 0px #80808066"
              p="10px"
            >
              <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
                {t("printableFiles")}
              </Text>

              <Box
                bg="#EDEDED"
                borderRadius="8px"
                alignItems="center"
                p="25px"
                w="100%"
              >
                <Text
                  textAlign="center"
                  color="main_1"
                  fontSize="18px"
                  fontWeight="600"
                >
                  {t("printableFiles_msg")}
                </Text>
              </Box>
            </Box>
            <Box
              w="100%"
              borderRadius="8px"
              boxShadow="0px 1px 5px 0px #80808066"
              p="10px"
              mt="20px"
            >
              <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
                {t("latestUpdates")}
              </Text>

              <Box
                bg="#EDEDED"
                borderRadius="8px"
                alignItems="center"
                p="25px"
                w="100%"
              >
                <Text
                  textAlign="center"
                  color="main_1"
                  fontSize="18px"
                  fontWeight="600"
                >
                  {t("latestUpdates_msg")}
                </Text>
              </Box>
            </Box>
          </Box>
        </Stack> */}
        {/* <Box w="100%" my="30px" borderRadius="8px" p="10px">
          <Text color="main_1" fontSize="20px" fontWeight="600">
            {t("chapters")}
          </Text>

          <Text color="main_1" fontSize="16px">
            {chapters?.length == 0 && t("chapters_msg")}
          </Text>

          <Slider {...settings} className="slider">
            {chapters.map((chapter, index) => {
              return (
                <HStack
                  className="chapters-slider"
                  key={index}
                  borderRadius="10px"
                  backgroundColor="main_1_light"
                  gap={2}
                  p="10px"
                  w={{ base: "100%", md: "100%", lg: "25%" }}
                  cursor="pointer"
                >
                  <Avatar
                    name={chapter?.name}
                    src={baseUrl + "/" + chapter?.logo}
                    borderRadius="8px"
                    size="lg"
                    background="transparent"
                  />
                  <Box>
                    <Text fontWeight="bold" fontSize="20px">
                      {chapter?.name}
                    </Text>
                    <Text fontWeight="400" fontSize="18px">
                      {chapter?.videos_count} videos
                    </Text>
                  </Box>
                </HStack>
              );
            })}
          </Slider>
        </Box> */}
      </Container>
    </Layout>
  );
};

export default withTranslation()(StudentHome);
