import React, { useMemo } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Container,
  Flex,
  Box,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import CFooter from "./footer";
import Loading from "../UI/loading";
import TableLoading from "../UI/table-loading";

const CTable = ({
  Data,
  Columns,
  Actions,
  footerBtnTitle,
  selectedData,
  ActionsData,
  currentpage,
  setPage,
  totalPage,
  setPageNumber,
  isLoading = false,
}) => {
  const data = useMemo(
    () =>
      Data?.map((value) => {
        return {
          ...value,
          Actions: ActionsData ? ActionsData(value) : Actions,
        };
      }),
    [Data]
  );

  const columns = useMemo(
    () =>
      Columns?.map((column) => {
        return {
          Header: column.Header,
          accessor: column.accessor,
        };
      }),
    [Columns]
  );

  const {
    state,
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: {
        pageIndex: currentpage,
      },
      manualPagination: true,
      pageCount: totalPage,
    },

    useGlobalFilter,

    useSortBy,
    usePagination
  );

  return (
    <>
      <Box 
        maxH="600px" 
        overflow="auto"
      >
        <Table
          {...getTableProps()}
          bg="white"
          mb="6"
          borderRadius="6px"
          position="relative"
        >
          <Thead 
            bg="#f5f5f5" 
            color="black"
            position="sticky"
            top={0}
            zIndex={2}
            shadow="sm"
          >
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    position={index == 0 && "sticky"}
                    left={index == 0 && 0}
                    zIndex={index == 0 && 2}
                    bg="inherit"
                    color="black"
                    {...column.getHeaderProps(
                      column.Header === "" ? "" : column.getSortByToggleProps()
                    )}
                  >
                    <Flex>
                      <>
                        {column.render("Header")}
                        {column.Header === "" ? (
                          ""
                        ) : (
                          <chakra.span
                            style={{
                              paddingLeft: "10px",
                              margin: "-1px",
                            }}
                            display="inline-block"
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              )
                            ) : (
                              <TiArrowUnsorted fontSize="18px" />
                            )}
                          </chakra.span>
                        )}
                      </>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          {!isLoading ? (
            <Tbody {...getTableBodyProps()} color="black">
              {page.map((row) => {
                prepareRow(row);

                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => selectedData(row.original)}
                  >
                    {row.cells.map((cell) => (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Loading />
          )}
        </Table>
      </Box>

      {Data.length > 0 ? (
        <CFooter
          setPageNumber={setPageNumber}
          footerBtnTitle={footerBtnTitle}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          setPage={setPage}
          totalPage={totalPage}
          currentpage={currentpage}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CTable;
