import { fork, put } from "redux-saga/effects";
import { appReadyAction } from "./globalActions";
import authSaga from "../modules/auth/Saga";
import LessonSaga from "../modules/admin-modules/lessons/Saga";
import TeacherSaga from "../modules/admin-modules/teachers/Saga";
import VideoSaga from "../modules/admin-modules/videos/Saga";
import StudentSaga from "../modules/admin-modules/students/Saga";
import GroupSaga from "../modules/admin-modules/groups/Saga";
import gradeSaga from "../modules/admin-modules/grades/Saga";
import SubjectSaga from "../modules/admin-modules/subjects/Saga";
import ChapterSaga from "../modules/admin-modules/chapters/Saga";
import StudentChapter from "../modules/students-modules/chapters/Saga";
import chapterVideoSaga from "../modules/students-modules/videos/Saga";
import chapterLessonSaga from "../modules/students-modules/lessons/Saga";
import userSaga from "../modules/user/Saga";
import LectureSaga from "../modules/admin-modules/lectures/Saga";
import EventSaga from "../modules/admin-modules/events/Saga";
import TeacherAssistantSaga from "../modules/admin-modules/teachersAssistant/Saga";
import ExamSaga from "../modules/admin-modules/exams/Saga";
import chapterExamSaga from "../modules/students-modules/exams/Saga";
import QuestionBankSaga from "../modules/admin-modules/questions-bank/Saga";
import questionsSaga from "../modules/students-modules/questions/Saga";
import RequestSaga from "../modules/admin-modules/video-requests/Saga";
import ReportsSaga from "../modules/admin-modules/reports/Saga";
import studentExamSaga from "../modules/students-modules/student-exams/Saga";
import studentPerformance from "../modules/students-modules/performance/Saga";
import redoRequestsSaga from "../modules/admin-modules/redo-requests/Saga";
import PaymentSaga from "../modules/admin-modules/payment/Saga";
import HomeworkSaga from "../modules/admin-modules/homework/Saga";
import chapterHomeworkSaga from "../modules/students-modules/homework/Saga";
import AdminSectionSaga from "../modules/admin-modules/sections/Saga";
import SectionsSaga from "../modules/students-modules/sections/Saga";
import LivevideoSaga from "../modules/admin-modules/liveVideos/Saga";

export default function* () {
  yield put(appReadyAction());
  yield fork(authSaga);
  yield fork(userSaga);
  yield fork(LessonSaga);
  yield fork(TeacherSaga);
  yield fork(VideoSaga);
  yield fork(StudentSaga);
  yield fork(GroupSaga);
  yield fork(gradeSaga);
  yield fork(SubjectSaga);
  yield fork(LectureSaga);
  yield fork(ChapterSaga);
  yield fork(ExamSaga);
  yield fork(QuestionBankSaga);
  yield fork(StudentChapter);
  yield fork(chapterVideoSaga);
  yield fork(chapterLessonSaga);
  yield fork(chapterExamSaga);
  yield fork(EventSaga);
  yield fork(TeacherAssistantSaga);
  yield fork(questionsSaga);
  yield fork(RequestSaga);
  yield fork(ReportsSaga);
  yield fork(studentExamSaga);
  yield fork(studentPerformance);
  yield fork(redoRequestsSaga);
  yield fork(PaymentSaga);
  yield fork(HomeworkSaga);
  yield fork(chapterHomeworkSaga);
  yield fork(AdminSectionSaga);
  yield fork(LivevideoSaga);
  yield fork(SectionsSaga);
}
