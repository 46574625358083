import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiDownload2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import AddVoucher from "../../../containers/student/models/add-voucher";
import VideoCard from "../../../containers/student/video/video";
import VideoPreview from "../../../containers/student/video/videoPreview";
import axiosInstance from "../../../helpers/api";
import { getVideosByChapterIdRequest } from "../../../modules/students-modules/videos/Actions";

const StudentVideo = (props) => {
  const [selectedData, setSelectedData] = useState({});
  const [selectedindex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playBack, setPlayBack] = useState("");
  const [showPlaylist, setShowPlaylist] = useState(false);
  const baseUrl = "https://asquera.com/asquera";
  const [getSearchParams, setSearchParams] = useSearchParams();
  const video_id = getSearchParams.get("video");
  const chapter_name = getSearchParams.get("name");
  const params = useParams();
  const dispatch = useDispatch();

  const chapterVideos = useSelector((state) => state.chapterVideos.chapterVideos);
  const isLoading = useSelector((state) => state.chapterVideos.isLoading);

  useEffect(() => {
    if (params?.id) {
      dispatch(getVideosByChapterIdRequest(params?.id));
    }
  }, [params?.id]);

  useEffect(() => {
    if (chapterVideos?.length > 0) {
      const firstVideo = chapterVideos[0];
      console.log(firstVideo);
      setSelectedData(firstVideo);
      setSelectedIndex(0);
      setSearchParams({
        video: firstVideo.id,
        name: chapter_name
      });
    }
  }, [chapterVideos]);

  const getVideoData = async (id) => {
    const res = await axiosInstance.get(`/students/videos/otp/${id}`);
    await setOtp(res?.data?.otp);
    await setPlayBack(res?.data?.playbackInfo);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (video_id && chapterVideos?.length > 0) {
      const filteredData = chapterVideos?.find((data) => data?.id == video_id);
      if (filteredData) {
        setSelectedData(filteredData);
        const index = chapterVideos.findIndex(video => video.id == video_id);
        setSelectedIndex(index);
      }
    }
  }, [video_id, chapterVideos]);

  useEffect(() => {
    if (selectedData?.id) {
      if (selectedData?.video_type == 1) getVideoData(selectedData?.id);
    }
  }, [selectedData]);

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" px={{ base: 2, md: 4 }} p={{ base: 2, md: 4, lg: 6, sm: 6 }}>
        <VStack spacing={6} w="100%" alignItems="flex-start" mt="20px">
          <Breadcrumb fontSize="sm">
            <BreadcrumbItem>
              <Link to="/videos/sections">
                <Text>Video</Text>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text textTransform="capitalize">{chapter_name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          {chapterVideos?.length === 0 && (
            <Heading as="h6" color="main_1" textAlign="center" w="100%" fontSize="lg">
              You Didn't pay for this chapter
              <a
                href="https://wa.me/+201025830160"
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
              >
                , Connect with the assistant!
              </a>
            </Heading>
          )}

          <Flex
            w="100%"
            gap={{ base: "4", xl: "6" }}
            direction={{ base: "column", xl: "row" }}
          >
            {/* Video Player Section */}
            <Box flex="1" w="100%">
              {selectedData?.url == "no" ? (
                <>
                  <Heading>Coming Soon</Heading>
                  <VStack spacing="6" alignItems="flex-start" w="100%">
                    {/* Mobile Playlist Toggle */}
                    <Button
                      display={{ base: "block", xl: "none" }}
                      w="100%"
                      variant="outline"
                      onClick={() => {
                        setShowPlaylist(!showPlaylist);
                        window.scrollTo({ top: 600, behavior: 'smooth' });
                      }}
                      mb="4"
                    >
                      {showPlaylist ? "Hide Playlist" : "Show Playlist"}
                    </Button>
                  </VStack>
                </>
              ) : (
                chapterVideos?.length > 0 &&
                selectedData && (
                  <VStack spacing="6" alignItems="flex-start" w="100%">
                    {/* Mobile Playlist Toggle */}
                    <Button
                      display={{ base: "block", xl: "none" }}
                      w="100%"
                      variant="outline"
                      onClick={() => {
                        setShowPlaylist(!showPlaylist);
                        window.scrollTo({ top: 600, behavior: 'smooth' });
                      }}
                      mb="4"
                    >
                      {showPlaylist ? "Hide Playlist" : "Show Playlist"}
                    </Button>
                    <VideoPreview
                      data={selectedData}
                      loading={loading}
                      otp={otp}
                      playBack={playBack}
                    />
                    {/* Video Materials */}
                    {selectedData?.files?.length > 0 && (
                      <VStack w="100%" alignItems="flex-start" color="main_1" spacing="4">
                        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="500">
                          Video materials
                        </Text>
                        <Flex w="100%" flexWrap="wrap" gap="3">
                          {selectedData?.files?.map((val, idx) => (
                            <HStack
                              key={idx}
                              w={{ base: "100%", sm: "48%", md: "31.5%" }}
                              h="60px"
                              bg="gray.50"
                              justify="space-between"
                              borderRadius="md"
                              p="3"
                              position="relative"
                              transition="all 0.2s"
                              _hover={{ bg: "gray.100" }}
                            >
                              <Text fontSize="sm">File title</Text>
                              <Square
                                size="32px"
                                bg="main_1"
                                color="white"
                                borderRadius="md"
                              >
                                <Icon as={RiDownload2Line} />
                              </Square>
                              <a
                                href={`${baseUrl}/${val?.file}`}
                                download
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "absolute",
                                }}
                                target="_blank"
                              />
                            </HStack>
                          ))}
                        </Flex>
                      </VStack>
                    )}
                  </VStack>
                )
              )}
            </Box>

            {/* Playlist Section */}
            <Box
              display={{ base: showPlaylist ? "block" : "none", xl: "block" }}
              w={{ base: "100%", xl: "360px" }}
              h={{ base: "100%", xl: "calc(100vh - 100px)" }}
              position={{ base: "relative", xl: "sticky" }}
              top={{ xl: "20px" }}
              overflowY="auto"
              bg="white"
              borderRadius="xl"
              boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
              border="2px solid"
              borderColor="gray.100"
            >
              {/* Header */}
              <Box
                p={4}
                borderBottom="2px solid"
                borderColor="gray.100"
                bg="gray.50"
                borderTopRadius="xl"
              >
                <Text
                  fontWeight="600"
                  fontSize="md"
                  color="gray.700"
                  fontFamily="heading"
                >
                  {chapter_name} Videos ({chapterVideos?.length})
                </Text>
              </Box>

              {/* Videos List */}
              <VStack
                spacing="1"
                w="100%"
                align="stretch"
                maxH={{ xl: "calc(100vh - 160px)" }}
                overflowY="auto"
                py={2}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    bg: 'gray.50',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    bg: 'gray.300',
                    borderRadius: 'full',
                  },
                }}
              >
                {chapterVideos?.map((data, idx) => (
                  <Box
                    key={idx}
                    mx={2}
                    borderRadius="md"
                    transition="all 0.2s"
                    _hover={{ bg: 'gray.50' }}
                  >
                    <VideoCard
                      data={data}
                      index={idx}
                      selectedindex={selectedindex}
                      isSelected={selectedData?.id === data?.id}
                      handelClick={() => {
                        setSelectedIndex(idx);
                        if (
                          data?.views_count?.views_count <= data?.max_allowed_views ||
                          (data?.suspended == 1 && data?.user_video_suspended != 0)
                        ) {
                          setSelectedData(data);
                          setSearchParams({ video: data?.id, name: chapter_name });
                          if (window.innerWidth < 1280) {
                            setShowPlaylist(false);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }
                        }
                      }}
                    />
                  </Box>
                ))}
              </VStack>
            </Box>
          </Flex>
        </VStack>
      </Container>
      <AddVoucher video_id={selectedData?.id} />
    </Layout>
  );
};

export default StudentVideo;